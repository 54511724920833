import { loadStripe } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'
import { Player } from '@lottiefiles/react-lottie-player'
import React, { useCallback, useEffect, useReducer, useState } from 'react'

import { Body, Root } from './styles/base'
import Carousel from './components/Carousel'
import CheckoutModal from './components/CheckoutModal'
import InformationCard from './components/InformationCard'

import 'pure-react-carousel/dist/react-carousel.es.css'

const AlertContainer = styled.div`
  align-items: center;
  bottom: 2vh;
  display: flex;
  justify-content: center;
  position: fixed;
  margin-left: 5vw;
  margin-right: 5vw;
  transform: translateY(10vh);
  transition: transform 300ms ease-in-out;
  width: 90vw;
  z-index: 10;

  @media screen and (max-aspect-ratio: 1/1) {
    transform: translateY(20vh);

    ${({ alertShown }) => !!alertShown && css`
      transform: translateY(0);
    `}
  }
  
  ${({ alertShown }) => !!alertShown && css`
    transform: translateY(0);
  `}
`

const Alert = styled.div`
  background-color: white;
  border: 1px solid #E38091;
  border-radius: 20px;
  color: #E38091;
  padding: 1rem 2rem;
  position: relative;
`

const Cross = styled.div`
  cursor: pointer;

  :before, :after {
    background-color: white;
    content: ' ';
    height: 10px;
    left: 9px;
    position: absolute;
    top: 5px;
    width: 2px;
  }

  :before {
    transform: rotate(45deg);
  }

  :after {
    transform: rotate(-45deg);
  }
`

const CloseButton = styled.a`
  align-items: center;
  background-color: #E38091;
  border-radius: 10px;
  cursor: pointer;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: -5px;
  top: -7px;
  width: 20px;
`

function reducer(state, action) {
  switch (action.type) {
    case 'useEffectUpdate':
      return { ...state, ...action.payload }
    case 'setBottomHeight':
      return { ...state, bottomHeight: action.payload }
    case 'setDisplayMandatory':
      return { ...state, displayMandatory: action.payload }
    case 'setEmail':
      return { ...state, email: action.payload, displayMandatory: false }
    case 'setLoading':
      return { ...state, loading: action.payload }
    case 'setEmailLoading':
      return { ...state, emailLoading: action.payload }
    case 'setNewsletter':
      return { ...state, newsletter: action.payload }
    case 'setError':
      return { ...state, error: action.payload }
    case 'setIsModalOpen':
      return { ...state, isModalOpen: action.payload }
    case 'setQuantity':
      return { ...state, quantity: action.payload, displayMandatory: false }
    case 'setShippingCountry':
      return { ...state, shippingCountry: action.payload, displayMandatory: false }
    default:
      throw new Error();
  }
}

const Checkout = () => {
  const [state, dispatch] = useReducer(reducer, {
    bottomHeight: 100,
    countries: [],
    displayMandatory: false,
    email: '',
    emailLoading: false,
    error: null,
    isModalOpen: false,
    loading: true,
    newsletter: true,
    product: null,
    quantity: 1,
    shippingCountry: 'FR',
    stock: 0,
    stripe: null
  })
  const [alertMessage, setAlertMessage] = useState('')
  const [alertShown, setAlertShown] = useState(false)

  useEffect(() => {
    const fetchConfig = async () => {
      const {
        countries,
        product,
        stock,
        stripePublicKey
      } = await fetch(`${process.env.REACT_APP_SERVER_URL}/config`)
        .then(res => res.json())

      dispatch({
        type: 'useEffectUpdate',
        payload: {
          countries,
          loading: false,
          product,
          stock: Math.max(stock, 0),
          stripe: await loadStripe(stripePublicKey)
        }
      })
    }

    fetchConfig()
  }, [dispatch])

  const onClick = useCallback(() => {
    const createStripeSession = async () => {
      let sessionId

      try {
        if (state.stock) {
          dispatch({ type: 'setLoading', payload: true })
          try {
            const session = await fetch(`${process.env.REACT_APP_SERVER_URL}/checkout-session`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                country: state.shippingCountry,
                email: state.email,
                newsletter: state.newsletter,
                quantity: state.quantity,
              })
            }).then((res) => res.json())

            sessionId = session.sessionId
          } catch (err) {
            console.log(err)
          }

          if (!sessionId) {
            return alert('Une erreur est survenue, veuillez recharger la page et réessayer s\'il-vous-plaît')
          }

          const { error } = await state.stripe.redirectToCheckout({ sessionId })

          if (error) {
            dispatch({ type: 'setError', payload: error })
            dispatch({ type: 'setLoading', payload: false })
          }
        } else {
          dispatch({ type: 'setEmailLoading', payload: true })
          dispatch({ type: 'setIsModalOpen', payload: false })
          try {
            const { status } = await fetch(`${process.env.REACT_APP_SERVER_URL}/out-of-stock`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email: state.email,
                newsletter: state.newsletter,
              })
            }).then((res) => res.json())

            if (status === 'ok') {
              dispatch({ type: 'setEmailLoading', payload: false })
              setAlertMessage('Merci, tu seras prévenu(e) du retour en stock du livre ! :)')
              setTimeout(setAlertShown, 5000, false)
              return setAlertShown(true)
            }
          } catch (err) {
            console.log(err)
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (state.email && ((state.quantity && state.shippingCountry) || !state.stock)) {
      createStripeSession()
    } else {
      dispatch({ type: 'setDisplayMandatory', payload: true })
    }
  }, [state.email, state.newsletter, state.quantity, state.shippingCountry, state.stock, state.stripe])

  const openModal = useCallback(() => {
    dispatch({ type: 'setIsModalOpen', payload: true })
  }, [])

  const closeModal = useCallback(() => {
    dispatch({ type: 'setIsModalOpen', payload: false })
  }, [])

  const setShippingCountry = useCallback((event) => {
    dispatch({ type: 'setShippingCountry', payload: event.target.value })
  }, [])

  const setEmail = useCallback((event) => {
    dispatch({ type: 'setEmail', payload: event.target.value })
  }, [])

  const setQuantity = useCallback((event) => {
    dispatch({ type: 'setQuantity', payload: event.target.value })
  }, [])

  const setNewsletter = useCallback((event) => {
    dispatch({ type: 'setNewsletter', payload: event.target.checked })
  }, [])

  const hideAlert = useCallback(() => {
    setAlertShown(false)
  }, [])

  if (state.loading) {
    return (
      <Body>
        <Root>
          <div className='content-container'>
            <div className='content'>
              <Player
                src='https://assets10.lottiefiles.com/datafiles/YwCfs4ZvIjyQw89/data.json'
                autoplay
                loop
              />
            </div>
          </div>
        </Root>
      </Body>
    );
  }

  return (
    <Body>
      <Root>
        <div className='content-container'>
          <div className='content'>
            <div className='body'>
              <Carousel stock={!!state.stock} />

              <InformationCard
                description={state.product.description}
                displayMandatory={state.displayMandatory}
                email={state.email}
                loading={state.emailLoading}
                motivation={state.product.motivation}
                newsletter={state.newsletter}
                price={`${parseFloat(state.product.price / 100).toFixed(2)} €`}
                setEmail={setEmail}
                setNewsletter={setNewsletter}
                showModal={openModal}
                soldOut={onClick}
                stock={!!state.stock}
                title={state.product.title}
              />
            </div>
          </div>

          <CheckoutModal
            bookPrice={state.product.price}
            closeModal={closeModal}
            countries={state.countries}
            displayMandatory={state.displayMandatory}
            email={state.email}
            isOpen={state.isModalOpen}
            newsletter={state.newsletter}
            onNextClick={onClick}
            quantity={state.quantity}
            setEmail={setEmail}
            setNewsletter={setNewsletter}
            setQuantity={setQuantity}
            setShippingCountry={setShippingCountry}
            shippingCountry={state.shippingCountry}
            stock={!!state.stock}
          />
        </div>
      </Root>
      <AlertContainer alertShown={!!alertShown}>
        <Alert>
          {alertMessage}
          <CloseButton onClick={hideAlert}>
            <Cross />
          </CloseButton>
        </Alert>
      </AlertContainer>
    </Body>
  )
}

export default Checkout
