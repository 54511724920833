import React from 'react'
import { Link } from 'react-router-dom'

import './CanceledOrSuccess.css'

const Canceled = () => (
  <div className='content-container'>
    <div className='canceled-or-success-container'>
      <p className='canceled-or-success-title'>
        Oups, petit crash...
      </p>
      <p className='canceled-or-success-subtitle'>
        Votre paiement n'a pas abouti, vous ne serez pas débité.
      </p>
      <Link to='/' className='canceled-or-success-button'>
        Retourner à la page d'accueil
      </Link>
    </div>
  </div>
)

export default Canceled
