import styled, { css } from 'styled-components'
import { BubbleLoader } from 'react-css-loaders'
import React, { useCallback, useEffect, useState } from 'react'

import Button from './Button'
import heart from '../heart.png'
import {
  Checkbox,
  EmailLabel,
  FormContainer,
  Input, Mandatory,
  NewsletterLabel,
  OutOfStockText,
  ValidateButtonHeart
} from '../styles/form'

const Section = styled.section`
  display: flex;
  border-top-left-radius: 50px;
  flex: 1 1 0%;
  background-color: transparent;
  margin-left: 10px;
  width: 100%;
  
  @media screen and (max-aspect-ratio: 1/1) {
    margin-left: 0;
    margin-top: 10px;
  }
`

const PinkSection = styled(Section)`
  background-color: #FFC0CF;
  height: 100%;
  margin-left: -50px;
  min-width: 30vw;
  z-index: 1;
  
  ${({ minHeight }) => css`
    min-height: ${Math.max(minHeight, 0)}px;
  `}
  
  @media screen and (max-aspect-ratio: 1/1) {
    bottom: 0;
    height: auto;
    margin-left: 0;
    position: fixed;
  }
`

const BlueSection = styled(Section)`
  background-color: #CAF9F4;
`

const YellowSection = styled(Section)`
  background-color: #FFF6B8;
`

const WhiteSection = styled(Section)`
  background-color: white;
  color: black;
  padding: 30px;
  max-height: 100vh;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  ::-webkit-scrollbar {
    display: none !important;
  }
  
  @media screen and (max-aspect-ratio: 1/1) {
    padding-bottom: 10px;
  }
`

const Title = styled.h5`
  font-family: 'Bebrush', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
  margin-top: 0;
  
  @media screen and (max-aspect-ratio: 1/1) {
    font-size: 1em;
    margin-bottom: 0;
  }
`

const TitleSpan = styled.span`
  margin-left: 0.5em;

  @media screen and (max-aspect-ratio: 1/1) {
    margin-left: 0.7em;
  }
`

const Description = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  margin: 0.4em 0;
  text-align: justify;
  
  @media screen and (max-aspect-ratio: 1/1) {
    font-size: 0.75em;
  }
`

const Motivation = styled(Description)`
  margin-top: 1em;
  white-space: pre-line;
  
  @media screen and (max-aspect-ratio: 1/1) {
    margin-top: 0;
  }

  ${({ showMotivation }) => !showMotivation && css`
    @media screen and (max-aspect-ratio: 1/1) {
      display: none;
    }
  `}
`

const LeftBloc = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  max-width: 100%;
  
  @media screen and (max-aspect-ratio: 1/1) {
    flex: 3.3;
  }
`

const RightBloc = styled.div`
  align-items: flex-end;
  display: none;
  flex: 1;
  flex-direction: column;
  
  @media screen and (max-aspect-ratio: 1/1) {
    display: flex;
  }
`

const Price = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0.1em;
  z-index: 1;
  
  @media screen and (max-aspect-ratio: 1/1) {
    font-size: 0.8em;
  }
`

const MobilePriceBloc = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`

const PriceBloc = styled(MobilePriceBloc)`
  min-height: 60px;
  margin-right: auto;
  margin-bottom: 0.5em;

  @media screen and (max-aspect-ratio: 1/1) {
    display: none;
  }
`

const Heart = styled.img`
  position: absolute;
  height: 60px;
  z-index: 0;
`

const ShowMotivation = styled.a`
  border: none;
  color: #FFC0CF;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.7em;
  font-weight: 600;
  margin: 0.4em 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  
  ${({ showMotivation }) => showMotivation && css`
    margin-top: -0.65em;
  `}
  
  @media screen and (min-aspect-ratio: 1/1) {
    display: none;
  }
`

const ButtonContainer = styled.div`
  @media screen and (min-aspect-ratio: 1/1) {
    padding-bottom: 40%;
  }
  
  @media screen and (min-aspect-ratio: 16/9) {
    padding-bottom: 40vh;
  }
`

const EmailInput = styled(Input)`
  margin-top: 0;
`

const DesktopOnly = styled.div`
  @media screen and (max-aspect-ratio: 1/1) {
    display: none;
  }
`

const MobileOnly = styled.div`
  @media screen and (min-aspect-ratio: 1/1) {
    display: none;
  }
`

const Loader = styled(BubbleLoader)`
  margin-bottom: 0;
  margin-top: 2rem;
`

const InformationCard = ({
  description,
  displayMandatory,
  email,
  loading,
  motivation,
  newsletter,
  price,
  setEmail,
  setNewsletter,
  showModal,
  soldOut,
  stock,
  title
}) => {
  const [showMotivation, setShowMotivation] = useState(false)
  const [minHeight, setMinHeight] = useState(0)

  const toggleShowMotivation = useCallback(() => {
    setShowMotivation(!showMotivation)
  }, [showMotivation])

  const setWindowDimensionsCallback = useCallback(() => {
    setMinHeight(window.innerHeight - (window.innerWidth / (9 / 11)) + 50)
  }, [])

  useEffect(() => {
    setWindowDimensionsCallback()

    window.addEventListener('resize', setWindowDimensionsCallback)

    return () => window.removeEventListener('resize', setWindowDimensionsCallback)
  }, [setWindowDimensionsCallback])

  return (
    <PinkSection minHeight={minHeight}>
      <YellowSection>
        <BlueSection>
          <WhiteSection>
            <LeftBloc>
              <Title>
                {title}
                <TitleSpan>book</TitleSpan>
              </Title>
              <PriceBloc>
                <Heart src={heart} />
                <Price>{price}</Price>
              </PriceBloc>
              <Description>{description}</Description>
              <Motivation showMotivation={showMotivation}>
                {motivation.replace(/\\n/g, '\n')}
              </Motivation>
              <ShowMotivation
                showMotivation={showMotivation}
                href='#'
                onClick={toggleShowMotivation}
              >
                {showMotivation ? '__' : 'En savoir +'}
              </ShowMotivation>
              {!stock && (
                <DesktopOnly>
                  <OutOfStockText>Laisse ton email pour être prévenu(e) de son retour ! ♡</OutOfStockText>
                  <FormContainer>
                    <EmailLabel>
                      <EmailInput
                        autoCapitalize='off'
                        autoComplete='email'
                        name='email'
                        required
                        onChange={setEmail}
                        placeholder='jane@doe.com'
                        type='email'
                        value={email}
                      />
                    </EmailLabel>

                    <NewsletterLabel>
                      <Checkbox
                        checked={newsletter}
                        onChange={setNewsletter}
                        type='checkbox'
                      />
                      S'inscrire à la newsletter
                    </NewsletterLabel>
                  </FormContainer>
                </DesktopOnly>
              )}
              {!!displayMandatory && (
                <DesktopOnly>
                  <Mandatory>
                    Tous les champs sont requis
                  </Mandatory>
                </DesktopOnly>
              )}
              <ButtonContainer>
                <DesktopOnly>
                  {!loading ? (
                    <Button onClick={stock ? showModal : soldOut}>
                        {stock ?
                          'Je l\'adopte !' : (
                            <>
                              {'Prévenez-moi ! '}
                              <ValidateButtonHeart>♡</ValidateButtonHeart>
                            </>
                          )}
                      </Button>
                  ) : (
                    <Loader color='pink' size={5} />
                  )}
                </DesktopOnly>
                <MobileOnly>
                  {!loading ? (
                    <Button onClick={showModal}>
                      {stock ?
                        'Je l\'adopte !' : (
                          <>
                            {'Prévenez-moi ! '}
                            <ValidateButtonHeart>♡</ValidateButtonHeart>
                          </>
                        )}
                    </Button>
                  ) : (
                    <Loader color='pink' size={5} />
                  )}
                </MobileOnly>
              </ButtonContainer>
            </LeftBloc>
            <RightBloc>
              <MobilePriceBloc>
                <Heart src={heart} />
                <Price>{price}</Price>
              </MobilePriceBloc>
            </RightBloc>
          </WhiteSection>
        </BlueSection>
      </YellowSection>
    </PinkSection>
  )
}

export default InformationCard
