import queryString from 'query-string'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import LogoImg from '../logo.png'

import './PrintAddress.css'

const Address = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  white-space: pre-line;
`

const AddressContent = styled.div`
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex: 1;
  height: 89.1mm;
  width: 129mm;
`

const Label = styled.div`
  align-items: center;
  border-radius: 5mm;
  border: 0.1mm solid white;
  display: flex;
  height: 89.1mm;
  justify-content: flex-end;
  padding: 5mm;
  position: absolute;
  text-align: end;
  width: 129mm;
`

const LabelContent = styled.div`
  position: relative;
  flex: 1;
`

const Leaf = styled.div`
  position: relative;
  height: 210mm;
  width: 297mm;
`

const LeafContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.img`
  height: 3cm;
  position: absolute;
  top: 0;
  left: 1cm;
`

const PrintAddress = () => {
  const location = useLocation()
  const [allAddresses, setAllAddresses] = useState([])

  useEffect(() => {
    const query = queryString.parse(location.search)
    let addressesParsed
    try {
      addressesParsed = JSON.parse(query.addresses)
    } catch (err) {
      alert(err)
    }

    if (Array.isArray(addressesParsed)) {
      const allAddressesParsed = []

      while (addressesParsed.length) {
        allAddressesParsed.push(addressesParsed.splice(0, 4))
      }

      setAllAddresses(allAddressesParsed)
    }
  }, [location.search])

  if (!Array.isArray(allAddresses) || !allAddresses.length) {
    return (
      <div>
        Il manque l'argument ?addresses=
      </div>
    )
  }

  return (
    <LeafContainer>
      {allAddresses.map(addresses => (
        <Leaf>
          {addresses.map((address, index) => (
            <Label className={`label${index}`}>
              <LabelContent>
                {address !== '' && <Logo src={LogoImg} />}
                <AddressContent>
                  <Address>{address}</Address>
                </AddressContent>
              </LabelContent>
            </Label>
          ))}
        </Leaf>
      ))}
    </LeafContainer>
  )
}

export default PrintAddress
