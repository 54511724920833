import styled from 'styled-components'
import React, { useEffect } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { Body, Root } from './styles/base'

const PlayerContainer = styled.div`
  min-width: 250px;
  width: 25vmin;
`

const Bravo = styled.p`
  color: #FFC0CF;
  font-family: 'Montserrat', sans-serif;
  font-size: x-large;
  font-weight: 600;
  margin-bottom: 0.5vmin;
  text-align: center;
  text-transform: uppercase;
`

const Subtitle = styled(Bravo)`
  font-size: large;
  text-transform: none;
  white-space: pre-line;
`

const Heart = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
`

const Success = () => {
  const history = useHistory()
  const location = useLocation()
  const sessionId = location.search.replace('?session_id=', '')

  useEffect(() => {
    if (!sessionId) {
      return history.push('/')
    }
  }, [sessionId, history])

  return (
    <Body>
      <Root>
        <div className='content-container'>
          <div className='canceled-or-success-container'>
            <Bravo>Bravo !</Bravo>

            <Subtitle>
              {'Ton carnet est en chemin !\nMerci pour ta commande '}

              <Heart>♡</Heart>
            </Subtitle>

            <PlayerContainer>
              <Player
                src='https://assets3.lottiefiles.com/packages/lf20_l6v5ivxa.json'
                autoplay
                loop
              />
            </PlayerContainer>

            <Link to='/' className='canceled-or-success-button'>
              Retourner à la page d'accueil
            </Link>
          </div>
        </div>
      </Root>
    </Body>
  )
}

export default Success
