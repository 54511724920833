import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Checkout from './Checkout'
import Success from './Success'
import Canceled from './Canceled'
import PrintAddress from './utils/PrintAddress'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/success">
          <Success />
        </Route>
        <Route path="/canceled">
          <Canceled />
        </Route>
        <Route path="/print-labels">
          <PrintAddress />
        </Route>
        <Route path="/">
          <Checkout />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
