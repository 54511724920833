import styled from 'styled-components'

export const Body = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const Root = styled.div`
  background-color: #FFE9EE;
  display: flex;
  min-height: 100vh;
  min-width: 100%;
  
  @media screen and (max-aspect-ratio: 1/1) {
    /* mobile viewport bugfix */
    min-height: -webkit-fill-available;
  }
`
