import Modal from 'react-modal'
import React, { useEffect, useState } from 'react'

import Button from './Button'
import {
  Checkbox,
  EmailLabel,
  FormContainer,
  Input,
  Mandatory,
  NewsletterLabel,
  OutOfStockText,
  PriceInfo,
  PriceInfoContainer,
  PriceInfoRow,
  PriceInfoTitle,
  PriceTotalInfo,
  QuantityLabel,
  RowContainer,
  Select,
  ShippingCountryLabel,
  ValidateButtonHeart
} from '../styles/form'

Modal.setAppElement('#root')

const CheckoutModal = ({
  bookPrice,
  closeModal,
  countries,
  displayMandatory,
  email,
  isOpen,
  newsletter,
  onNextClick,
  quantity,
  setEmail,
  setNewsletter,
  setQuantity,
  setShippingCountry,
  shippingCountry,
  stock
}) => {
  const [shippingPrice, setShippingPrice] = useState(0)

  useEffect(() => {
    const country = countries.find(country => country.code === shippingCountry)

    setShippingPrice(country.priceValue)
  }, [countries, shippingCountry])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          inset: 0,
          backgroundColor: 'white',
          borderColor: '#E38091',
          maxWidth: '80%',
          width: '400px'
        },
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2
        }
      }}
    >
      {!stock && (
        <OutOfStockText>
          Laisse ton email pour être prévenu(e) de son retour ! ♡
        </OutOfStockText>
      )}
      <FormContainer>
        {!!stock && (
          <RowContainer>
            <ShippingCountryLabel>
              Pays de livraison
              <Select
                onChange={setShippingCountry}
                value={shippingCountry}
              >
                {countries.map(option => (
                  <option key={option.code} value={option.code}>
                    {`${
                      option.value
                    } - ${
                      parseFloat(option.priceValue / 100).toFixed(2)
                    } €`}
                  </option>
                ))}
              </Select>
            </ShippingCountryLabel>

            <QuantityLabel>
              Quantité
              <Select
                onChange={setQuantity}
                value={quantity}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
              </Select>
            </QuantityLabel>
          </RowContainer>
        )}

        <EmailLabel>
          {stock ? 'Email' : ''}
          <Input
            autoCapitalize='off'
            autoComplete='email'
            name='email'
            placeholder='jane@doe.com'
            required
            onChange={setEmail}
            type='email'
            value={email}
          />
        </EmailLabel>

        <NewsletterLabel>
          <Checkbox
            checked={newsletter}
            onChange={setNewsletter}
            type='checkbox'
          />
          S'inscrire à la newsletter
        </NewsletterLabel>
      </FormContainer>

      {!!stock && (
        <PriceInfoContainer>
          <PriceInfoRow>
            <PriceInfoTitle>Sous-total :</PriceInfoTitle>
            <PriceInfo>
              {parseFloat(bookPrice * quantity / 100).toFixed(2)} €
            </PriceInfo>
          </PriceInfoRow>
          <PriceInfoRow>
            <PriceInfoTitle>Livraison :</PriceInfoTitle>
            <PriceInfo>
              {parseFloat(shippingPrice / 100).toFixed(2)} €
            </PriceInfo>
          </PriceInfoRow>
          <PriceInfoRow>
            <PriceInfoTitle>Total :</PriceInfoTitle>
            <PriceTotalInfo>
              {parseFloat((bookPrice * quantity + shippingPrice) / 100).toFixed(2)} €
            </PriceTotalInfo>
          </PriceInfoRow>
        </PriceInfoContainer>
      )}

      {!!displayMandatory && (
        <Mandatory>
          Tous les champs sont requis
        </Mandatory>
      )}

      <Button onClick={onNextClick}>
        {!stock ? 'Prévenez-moi ! ' : 'Ze valide ! '}<ValidateButtonHeart>♡</ValidateButtonHeart>
      </Button>
    </Modal>
  )
}

export default CheckoutModal
