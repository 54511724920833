import styled from 'styled-components'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const RowContainer = styled.div`
  display: flex;
`

export const ValidateButtonHeart = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: bolder;
`

export const Select = styled.select`
  /* styling */
  background-color: white;
  border: thin solid #E38091;
  border-radius: 4px;
  color: #E38091;
  outline-color: #FBBCCB;
  display: inline-block;
  font: inherit;
  line-height: 1.3rem;
  padding: 0.5em 3.5em 0.5em 1em;
  width: 100%;

  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  margin-top: 0.8rem;
  margin-left: -3px;

  /* arrow */
  background-image:
    linear-gradient(45deg, transparent 50%, #E38091 50%),
    linear-gradient(135deg, #E38091 50%, transparent 50%),
    linear-gradient(to right, #E38091, #E38091);
  background-position:
    calc(100% - 13px) calc(1em + 2px),
    calc(100% - 8px) calc(1em + 2px),
    calc(100% - 26px) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;

  :hover, :focus, :active {
    border-color: #FBBCCB;
    outline-color: #FBBCCB;
  
    /* arrow */
    background-image:
      linear-gradient(45deg, transparent 50%, #FBBCCB 50%),
      linear-gradient(135deg, #FBBCCB 50%, transparent 50%),
      linear-gradient(to right, #FBBCCB, #FBBCCB);
  }
`

export const Label = styled.label`
  align-self: center;
  color: #E38091;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  margin-bottom: 0.8rem;
  margin-left: 3px;
  width: 100%;
  display: flex;
  flex: 1;
`

export const Input = styled.input`
  border: thin solid #E38091;
  border-radius: 4px;
  box-shadow: none;
  color: #E38091;
  line-height: 1.3rem;
  margin-top: 0.8rem;
  padding: 0.5em 3.5em 0.5em 1em;
  
  ::placeholder {
    color: rgba(227, 128, 145, 0.6);
  }
`

export const ShippingCountryLabel = styled(Label)`
  flex: 3;
  margin-right: 5px;
`

export const QuantityLabel = styled(Label)`
  margin-left: 8px;
`

export const EmailLabel = styled(Label)`
  margin-left: 0;
`

export const NewsletterLabel = styled(Label)`
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
`

export const Checkbox = styled.input`
  margin-left: 0;
  margin-right: 5px;
`

export const Mandatory = styled.span`
  color: red;
  font-size: 0.7rem;
`

export const PriceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const PriceInfoRow = styled.div`
  color: #E38091;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  width: 10rem;
`

export const PriceInfoTitle = styled.div`
  display: flex;
  flex: 1;
`

export const PriceInfo = styled.div`
  display: flex;
  font-size: 0.9rem;
  justify-content: flex-end;
`

export const PriceTotalInfo = styled(PriceInfo)`
  font-weight: 600;
`

export const OutOfStockText = styled.p`
  color: #E38091;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 10px;
  text-align: justify;
`
