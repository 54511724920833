import React from 'react'
import styled from 'styled-components'

import star from '../star.png'

const PreorderButton = styled.button`
  background: #FFC0CF;
  border: none;
  border-radius: 50px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  outline: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-transform: uppercase;
  
  :hover {
    background: #FBBCCB;
    cursor: pointer;
  }
  
  :active {
    background: #F3B4C3;
    cursor: pointer;
  }
  
  @media screen and (max-aspect-ratio: 1/1) {
    font-size: 0.8rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }
`

const ButtonContainer = styled.div`
  flex: 0;
  padding-top: 2vmin;
  padding-bottom: 3.1vmin;
  position: relative;
`

const Star = styled.img`
  pointer-events: none;
  position: absolute;
`

const TopRightStar = styled(Star)`
  height: 3.5vmin;
  left: 77%;
  top: 0.8vmin;
  width: 3.5vmin;
`

const TopLeftStar = styled(Star)`
  height: 5vmin;
  left: 31%;
  top: 0;
  width: 5vmin;
`

const BottomLeftStar = styled(Star)`
  bottom: 0;
  height: 5vmin;
  left: 16%;
  width: 5vmin;
`

const BottomRightStar = styled(Star)`
  bottom: 2.4vmin;
  height: 2.5vmin;
  left: 25%;
  transform: rotate(-25deg);
  width: 2.5vmin;
`

/**
 * @param {JSX.Element} children
 * @param {string} label
 * @param {function} onClick
 * @param {boolean} showStars
 * @return {JSX.Element}
 * @constructor
 */
const Button = ({ children, label, onClick, showStars = false }) => {
  return (
    <ButtonContainer>
      <PreorderButton onClick={onClick}>
        {label || children}
      </PreorderButton>
      {showStars && (
        <>
          <TopLeftStar src={star} alt='decorative star' className='star' id='large-top-star' />
          <BottomLeftStar src={star} alt='decorative star' className='star' id='large-bottom-star' />
          <TopRightStar src={star} alt='decorative star' className='star' id='medium-top-star' />
          <BottomRightStar src={star} alt='decorative star' className='star' id='small-bottom-star' />
        </>
      )}
    </ButtonContainer>
  )
}

export default Button
