import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import {
  CarouselProvider,
  Image,
  Slide,
  Slider
} from 'pure-react-carousel'

import logo from '../logo.png'
import bath from '../bath.jpg'
import open from '../open.jpg'
import pencil from '../pencil.jpg'
import breakfast from '../breakfast.jpg'
import heartbreak from '../heartbreak.png'

import './Carousel.css'

const Logo = styled.img`
  position: absolute;
  top: 2%;
  width: 9.5rem;
  
  @media screen and (max-aspect-ratio: 1/1) {
    top: 5%;
    width: 25%;
  }
`

const Heartbreak = styled.img`
  max-height: 50vmin;
  pointer-events: none;
`

const HeartbreakContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  
  @media screen and (max-aspect-ratio: 1/1) {
    height: auto;
  }
`

const OutOfStock = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;
`

const Carousel = ({ stock }) => {
  const [heightAspectRatio, setHeightAspectRatio] = useState(11)

  const setWindowDimensionsCallback = useCallback(() => {
    const width = window.innerWidth
    const height = window.innerHeight

    setHeightAspectRatio(width > height && width - 100 < height ? 14 : 11)
  }, [])

  useEffect(() => {
    setWindowDimensionsCallback()

    window.addEventListener('resize', setWindowDimensionsCallback)

    return () => window.removeEventListener('resize', setWindowDimensionsCallback)
  }, [setWindowDimensionsCallback])

  return (
    <CarouselProvider
      isPlaying
      infinite
      naturalSlideWidth={9}
      naturalSlideHeight={heightAspectRatio}
      totalSlides={4}
    >
      <Slider className='carousel-slider'>
        <Slide index={0}><Image src={pencil} alt='book with pencil' className='image' /></Slide>
        <Slide index={1}><Image src={open} alt='open book' className='image' /></Slide>
        <Slide index={2}><Image src={breakfast} alt='book next to a breakfast' className='image' /></Slide>
        <Slide index={3}><Image src={bath} alt='book open over bath' className='image' /></Slide>
      </Slider>

      {!stock && (
        <HeartbreakContainer>
          <OutOfStock>Rupture de stock</OutOfStock>
          <Heartbreak
            src={heartbreak}
            alt='heart broken meaning out of stock'
          />
        </HeartbreakContainer>
      )}

      <Logo
        src={logo}
        alt='Logo JussVibes'
      />
    </CarouselProvider>
  )
}

export default Carousel
